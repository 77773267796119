import Serial from "./Utils/Serial";
import CommonResponses from "./Responses/CommonResponses";
import AutoservicioResponses from "./Responses/POSAutoservicioResponses";

export default class POSAutoservicio extends Serial {

    _CommonResponses;
    _AutoservicioResponses;

    constructor() {
        super();
        this._CommonResponses = new CommonResponses();
        this._AutoservicioResponses = new AutoservicioResponses();
    }

    initialization() {
        return this._send('0070', false);
    }

    initializationResponse() {
        return this._send('0080', true).then((response) => {
            return this.AutoservicioResponses.initializationResponse(response);
        });
    }

    loadKeys() {
        return this._send('0800', true).then(response => {
            return this._CommonResponses.loadKeysResponse(
                response,
                this._AutoservicioResponses.getResponseMessage
            );
        });
    }

    sale(amount, ticket, sendVoucher = false, sendStatus = false, callback = null) {
        amount = amount.toString().padStart(9, "0").slice(0, 9);
        ticket = ticket.toString().padStart(6, "0").slice(0, 20);
        let status = sendStatus ? "1":"0";
        let voucher = sendVoucher ? "1":"0";

        return this._send(`0200|${amount}|${ticket}|${voucher}|${status}`, true, this._intermediateResponse.bind(this, [callback]))
        .then((response) => {
            return this._AutoservicioResponses.saleResponse(response);
        });
    }

    multiCodeSale(amount, ticket, commerceCode = null, sendVoucher = false, sendStatus = false, callback = null) {
        amount = amount.toString().padStart(9, "0").slice(0, 9);
        ticket = ticket.toString().padStart(6, "0").slice(0, 20);
        commerceCode = commerceCode === null ? '0' : commerceCode;
        let status = sendStatus ? "1":"0";
        let voucher = sendVoucher ? "1":"0";

        return this._send(`0270|${amount}|${ticket}|${voucher}|${status}|${commerceCode}`, true, this._intermediateResponse.bind(this, [callback]))
        .then((response) => {
            return this._AutoservicioResponses.saleResponse(response);
        });
    }

    _intermediateResponse(callback, response) {
        if (typeof callback[0] === "function") {
            callback[0](this._CommonResponses.intermediateResponse(
                response,
                this._AutoservicioResponses.getResponseMessage
            ));
        }
    }

    lastSale(sendVoucher = false) {   
        let voucher = sendVoucher ? "1":"0";
        return this._send(`0250|${voucher}`).then((response) => {
            return this._AutoservicioResponses.saleResponse(response);
        });
    }

    refund() {
        return this._send(`1200`).then((response) => {
            return this._CommonResponses.refundResponse(
                response,
                this._AutoservicioResponses.getResponseMessage
            );
        })
    }

    closeDay(sendVoucher = false) {   
        let voucher = sendVoucher ? "1":"0";
        return this._send(`0500|${voucher}`).then((response) => {
            return this._AutoservicioResponses.closeResponse(response);
        })
    }
}