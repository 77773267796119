import WebSerialPort from "../Lib/WebSerial/WebSerialPort"

const ACK = 0x06;
const STX = 0x02;
const ETX = 0x03;

export default class Serial extends WebSerialPort {

    __ackTimeout;
    __responseTimeout;
    _responseCallback;
    _ackCallback;
    _connecting;
    _disconnecting;
    _waiting;

    constructor() {
        super();
        this.__ackTimeout = 2000;
        this.__responseTimeout = 150000;
        this._responseCallback = () => {};
        this._ackCallback = () => {};
        this._connecting = false;
        this._disconnecting =false;
        this._waiting = false;
    }

    _itsAnACK = (data) => {
        return (!data.length > 1) || data[0] === ACK;
    }

    _writeACK = async () => {
        this.writeData(new Uint8Array([ACK]))
        .catch(err => {
            console.log(`Unable to send ACK: ${err.message}`);
        });
    }

    autoConnect (baudRate = 115200) {
        return new Promise(async (resolve, reject) => {
            try {
                let authorizedPorts =  await this.getAuthorizedPorts();

                for (const port of authorizedPorts) {
                    this.port = port;
                    await this._open(baudRate);
                    
                    if(await this.poll()) {
                        resolve(true);
                        return;
                    }
                };

                resolve(false);
            }
            catch(err){
                reject(`Can't run autoConnect. error: ${err.message}`);
            }
        });
    }

    connect(baudRate = 115200) {
        return new Promise((resolve, reject) => {
            // Block so just one connect command can be sent at a time
            if (this._connecting === true) {
                reject("Another connect command was already sent and it is still waiting");
                return;
            }
            
            this._connecting = true;
            
            if(this.isOpen) this.closePort();

            return this.requestSerialPort().then(async () => {
                resolve(await this._open(baudRate));
            }).finally(() => this._connecting = false);
        });
    }

    disconnect() {
        return new Promise((resolve, reject) => {
            // Block so just one disconnect command can be sent at a time
            if (this._disconnecting === true) {
                reject("Another disconnect command was already sent and it is still waiting");
                return;
            }

            this._disconnecting = true;

            if(this.isOpen)
                return this.closePort()
                    .then(() => resolve(true))
                    .catch((e) => reject(`Error closing port. ${e.message}`))
                    .finally(() => this._disconnecting = false);

            this._disconnecting = false;
            resolve(true);
        });
    }

    async _open (baudRate = 115200) {
        let result = await this.openPort({baudRate: baudRate});
        
        // Aqui se reciben los datos del puerto serial. 
        this.on('data', async (data) => {

            // Primero, se recibe un ACK
            if (this._itsAnACK(data)) {
                if (typeof this._ackCallback==="function") {
                    this._ackCallback(data);
                }
                return;
            }

            // Si se recibió una respuesta (diferente a un ACK) entonces responder con un ACK y mandar el mensaje por callback
            await this._writeACK();
            if (typeof this._responseCallback==="function") {
                this._responseCallback(data);
            }
        });

        return(result);
    }

    _send(command, waitResponse = true, callback = null) {
        return new Promise((resolve, reject) => {
            if (!this.isOpen) {
                reject(`You have to connect to a POS to send this message: ${command}`);
                return;
            }
            // Block so just one message can be sent at a time
            if (this._waiting === true) {
                reject("Another message was already sent and it is still waiting for a response from the POS");
                return;
            }
            this._waiting = true;

            // Se establece timeout para recibir ACK desde el POS.
            let ackTimeout = setTimeout(() => {
                this._waiting = false;
                clearTimeout(responseTimeout)
                if(!waitResponse) resolve(false);
                reject("ACK has not been received in " + this.__ackTimeout + " ms.")
            }, this.__ackTimeout);

            this._ackCallback = () => {
                clearTimeout(ackTimeout);
                if (!waitResponse) {
                    this._waiting = false;
                    resolve(true);
                }
            }

            this.writeData(this._prepareCommand(command));

            // Se establece timeout para recibir respuesta desde el POS.
            let responseTimeout = setTimeout(() => {
                this._waiting = false
                reject(`Response of POS has not been received in ${this.__responseTimeout/1000} seconds`)
            }, this.__responseTimeout);

            this._responseCallback = (serialData) => {
                clearTimeout(responseTimeout);

                let response = new TextDecoder().decode(serialData.slice(1, -2));
                let functionCode = response.slice(0, 4);

                if (typeof callback==="function") {
                    if (functionCode==="0900") { // Sale status messages
                        callback(response);
                        return;
                    }

                    if (functionCode==="0261" || functionCode==="0291")
                        callback(response);
                }

                this._waiting = false;
                resolve(response);
            }
        });
    }

    _prepareCommand(command) {
        let hexData = new TextEncoder().encode(command);

        if(hexData[0] === STX)
            hexData =  hexData.slice(1);

        if(hexData[hexData.length - 1] !== ETX)
            hexData =  this._concatTypedArrays(hexData, new Uint8Array([ETX]));

        hexData = this._concatTypedArrays(hexData, new Uint8Array([this._LRC(hexData)]));

        return this._concatTypedArrays(new Uint8Array([STX]), hexData);
    }

    _LRC(hexData) {
        return hexData.reduce((accumulator, byte) => accumulator ^ byte, 0);
    }

    poll() {
        return this._send('0100', false);
    }
}