<template>
<div>
<modal v-model="isOpen">
    <div style="padding: 20px">

        <slot></slot>

        <div v-if="!isSupported">Transbank POS Intgrado no soportado en este navegador</div>
        <div v-if="isSupported">
            <div v-if="!isConnected" class=" mb10 flex align-center">
                <div class="s1 flex-fill">Transbank POS Integrado</div>
                <div class="bold">Desconectado</div>
            </div>
            <div v-if="!isConnected" class="mt10">
                <div class="mb5 bold">Vélocidad de transferencia (baudrate)</div>
                <div class="flex">
                    <select class="flex-fill">
                        <option value="115200">115200</option>
                        <option value="57600">57600</option>
                        <option value="19200">19200</option>
                        <option value="14400">14400</option>
                        <option value="9600">9600</option>
                        <option value="4800">4800</option>
                        <option value="2400">2400</option>
                    </select>
                    <button @click="Connect(baudrate)" class="btn invert">Conectar</button>
                </div>
            </div>
            <div v-if="isConnected" class="">
                <div class=" mb10 flex align-center">
                    <div class="s1 flex-fill">Transbank POS Integrado</div>
                    <div v-if="isConnected" class="cgreen bold">Conectado</div>
                </div>
                <div style="display: flex; flex-direction: column; gap: 5px;">      
                    <label class="mb10">
                        <input type="checkbox" v-model="autoConnect"> Auto Conectar <div class="checkmark"></div>
                    </label> 
                    <div style="display: flex; gap: 5px">
                        <button @click="modalSale={amount: '', id: Util.UUIDv4().substr(0, 6)}" class="btn" style="width: 50%" title="Sale">Registrar Venta</button>
                        <button @click="OpenRefund" class="btn" style="width: 50%" title="Refund">Anúlación Venta</button>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <button @click="Confirm({
                            title: '¿Ejecutar carga de llaves en el POS?',
                            text: 'Cargar Llaves',
                            function: () => {LoadKeys()}
                        })" class="btn" style="width: 50%" title="LoadKeys">Carga de Llaves</button>
                        <button @click="Poll" class="btn" style="width: 50%" title="Poll">Verificar Conexión</button>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <button @click="Confirm({
                            title: '¿Imprimir Detalle de Ventas en el POS?',
                            text: 'Imprimir',
                            function: () => {SalesDetail(true)},
                            text2: 'No Imprimir',
                            function2:  () => {SalesDetail(false)},
                        })" class="btn" style="width: 50%" title="SalesDetail">Detalle de Ventas</button>
                        <button @click="Confirm({
                            title: '¿Imprimir última venta en el POS?',
                            text: 'Imprimir',
                            function: () => {LastSale()}
                        })" class="btn" style="width: 50%" title="LastSale">Última Venta</button>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <button @click="Confirm({
                            title: '¿Imprimir total de ventas del terminal?',
                            text: 'Imprimir',
                            function: () => {GetTotals()}
                        })" class="btn" style="width: 50%" title="GetTotals">Resumen Totales</button>
                        <button @click="Close" class="btn invert" style="width: 50%" title="Close">Cierre de Caja</button>
                    </div>
                    <div style="display: flex; gap: 5px">
                        <button @click="Confirm({
                            title: '¿Cambiar máquina a POS normal?',
                            text: 'Cambiar',
                            function: () => {SetNormalMode()}
                        })" class="btn" style="width: 50%" title="SetNormalMode">Cambio a POS Normal</button>
                        <button @click="Confirm({
                            title: '¿Desconectar POS?',
                            text: 'Desconectar',
                            class: 'error-color',
                            function: () => {Disconnect()}
                        })" class="btn invert" style="width: 50%" title="Disconnect">Desconectar</button>
                    </div>
                </div>
            </div>

            <div class="flex align-center" style="margin-top: 10px">
                <div class="s1 flex-fill">Log</div>
                <button class="btn" @click="showLog = !showLog">{{ showLog ? 'Ocultar' : 'Mostrar' }}</button>
            </div>

            <textarea v-if="showLog" v-model="log" readonly style="font-family: monospace !important; font-size: 10px !important; width: 100%; background: transparent; color: inherit; border: none" rows="40" wrap="off"></textarea>

        </div>

    </div>
</modal>

<modal v-model="modalPassword">
    <div style="padding: 20px" v-if="modalPassword">
        <input
        label="Contraseña"
        type="password"
        v-model="modalPassword.password"/>
        <button class="btn invert" @click="Open">Ingresar</button>
    </div>
</modal>

<modal v-model="modalSale" title="Registrar Venta" desktopHeight="auto" desktopWidth="auto">
    <div style="padding: 20px" v-if="modalSale">
        <div class="btn input left" style="margin-bottom: 20px">
            <div class="label left s3 c2">Monto</div>
            <input v-model="modalSale.amount" type="text" class="w-label left s2" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
            <div class="flex" style="padding: 20px; gap: 5px">
                <div v-for="i in 10" :key="`mini-keyboard-${i}`" class="btn invert" @click="$set(modalSale, 'amount', modalSale.amount + (i-1).toString())">{{i-1}}</div>
                <div class="btn" @click="$set(modalSale, 'amount', modalSale.amount.substr(0, modalSale.amount.length-1))"><i class="icon icon-chevron-left"></i></div>
            </div>
        </div>
        <div class="btn input left" style="margin-bottom: 20px">
            <div class="label left s3 c2">Número Ticket/Boleta</div>
            <input v-model="modalSale.id" type="text" class="w-label left s2">
            <div class="flex" style="padding: 20px; gap: 5px">
                <div v-for="i in 10" :key="`mini-keyboard-2-${i}`" class="btn invert" @click="$set(modalSale, 'id', modalSale.id + (i-1).toString())">{{i-1}}</div>
                <div class="btn" @click="$set(modalSale, 'id', modalSale.id.substr(0, modalSale.id.length-1))"><i class="icon icon-chevron-left"></i></div>
            </div>
        </div>
        <button class="btn invert" style="width: 100%" @click="Sale(modalSale.amount, modalSale.id)">Ingresar</button>
    </div>
</modal>

<modal  v-model="modalRefund" title="Anulación Venta" desktopHeight="auto" desktopWidth="auto">
    <div style="padding: 20px" v-if="modalRefund">
        <div class="btn input left" style="margin-bottom: 20px">
            <div class="label left s3 c2">ID Operación</div>
            <input v-model="modalRefund.id" type="text" class="w-label left s2" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
            <div class="flex" style="padding: 20px; gap: 5px">
                <div v-for="i in 10" :key="`mini-keyboard-${i}`" class="btn invert" @click="$set(modalRefund, 'id', modalRefund.id + (i-1).toString())">{{i-1}}</div>
                <div class="btn" @click="$set(modalRefund, 'id', modalRefund.id.substr(0, modalRefund.id.length-1))"><i class="icon icon-chevron-left"></i></div>
            </div>
        </div>
        <button class="btn invert" style="width: 100%" @click="Refund(modalRefund.id)">Ingresar</button>
    </div>
</modal>

<modal v-model="modalConfirm" desktopHeight="auto" desktopWidth="auto">
    <div style="padding: 20px" v-if="modalConfirm">
        <div style="margin-bottom: 20px">{{ modalConfirm.title }}</div>
        <div class="flex" style="gap: 10px">
            <button class="btn flex-fill" @click="modalConfirm = null">Cancelar</button>
            <button class="btn invert flex-fill" @click="() => {modalConfirm.function(); modalConfirm = null}">{{ modalConfirm.text }}</button>
            <button v-if="modalConfirm.text2" class="btn invert flex-fill" @click="() => {modalConfirm.function2(); modalConfirm = null}">{{ modalConfirm.text2 }}</button>
        </div>
        
    </div>
</modal>

</div>
</template>

<script>
import POS from '../../transbank/POS' ;
export default {
    data() {
        return {
            isOpen: false,
            modalPassword: null,
            isSupported: false,
            isConnected: false,
            log: '',
            baudrate: 115200,
            showLog: false,
            autoConnect: false,
            modalSale: null,
            modalRefund: null,
            modalConfirm: null,
        }
    },
    mounted() {
        this.log = localStorage.transbankLog ? localStorage.transbankLog : '';
        this.autoConnect = localStorage.autoConnect ? true : false;
        this.isSupported = POS.Integrado.browserSupport;
        if(this.autoConnect)
        {
            this.Log(`> Autoconnect`);
            POS.Integrado.autoConnect().then((res) => {
                this.Log(`Autoconnect ok: ${JSON.stringify(res)}`);
                this.isConnected = res;
            }).catch((err) => {
                this.Log(`Autoconnect error: ${JSON.stringify(err)}`);
                this.Poll();
            });
        }
    },
    methods: {
        Open(password) {
            setTimeout(() => {
                this.isOpen = true;
            }, 300)
        },
        Connect(baudrate) {
            let validBaudrate = [115200, 57600, 19200, 14400, 9600, 4800, 2400];
            if(!baudrate) baudrate = prompt('Vélocidad de transferencia (Baudrate)', 115200);
            baudrate = parseInt(baudrate);
            if(validBaudrate.indexOf(baudrate) == -1) return console.log('Invalid baudrate');

            this.Log(`> Connect (${baudrate} bps)`);
            POS.Integrado.connect(baudrate).then((res) => {
                this.Log(`Connect ok: ${JSON.stringify(res)}`);
                this.isConnected = res;
                this.$emit(res ? 'OnConnectSuccess' : 'OnConnectError', {successful: res});
            }).catch(err => {
                this.Log(`Connect error: ${JSON.stringify(err)}`);
                this.$emit('OnConnectError', {successful: false, responseMessage: err});
            });
        },
        Disconnect() {
            this.Log(`> Disconnect`);
            
            POS.Integrado.disconnect().then((res) => {
                this.Log(`Disconnect ok: ${JSON.stringify(res)}`);
                this.isConnected = !res;
                this.$emit(res ? 'OnDisconnectSuccess' : 'OnDisconnectError', {successful: true});
            }).catch(err => {
                this.Log(`Disconnect error: ${JSON.stringify(err)}`);
                this.$emit('OnDisconnectError', {successful: false, responseMessage: err});
            });
        },
        Poll() {
            this.Log('> Poll');
            POS.Integrado.poll().then((res) => {
                this.Log('Poll ok: ' + JSON.stringify(res));
                this.isConnected = res;
                this.$emit(res ? 'OnPollSuccess' : 'OnPollError', {successful: res});
                if(this.isOpen) this.Info(res ? `Conexión OK` : `Sin Conexión`);
            }).catch(err => {
                this.Log('Poll error: ' + JSON.stringify(err));
                this.isConnected = false;
                this.$emit('OnPollError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Sin Conexión`);
            });
        },
        SetNormalMode() {
            this.Log('> SetNormalMode');
            POS.Integrado.setNormalMode().then((res) => {
                this.Log('SetNormalMode ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnSetNormalModeSuccess' : 'OnSetNormalModeError', {successful: res});
                if(res) this.isConnected = false;
                if(this.isOpen) this.Info(res ? `Cambio a POS normal OK` : `Error cambio POS normal`);
            }).catch(err => {
                this.Log('SetNormalMode error: ' + JSON.stringify(err));
                this.$emit('OnSetNormalModeError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Error cambio POS normal`);
            });
        },
        LoadKeys() {
            this.Log('> LoadKeys');
            POS.Integrado.loadKeys().then((res) => {
                this.Log('LoadKeys ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnLoadKeysSuccess' : 'OnLoadKeysError', res);
                if(this.isOpen) this.Info(res.successful ? `Carga de llaves exitosa<br>${res.responseMessage}` : `Error en carga de llaves: ${res.responseMessage}`);
            }).catch(err => {
                this.Log('LoadKeys error: ' + JSON.stringify(err))
                this.$emit('OnLoadKeysError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Error en carga de llaves`);
            });
        },
        Sale(amount, id) {
            
            if(isNaN(amount)) return this.modalSale ? this.Info('Total inválido') : console.log('Total inválido');
            if(!id) return this.modalSale ? this.Info('Número Ticket/Boleta inválido') : console.log('Invalid id');

            this.Log(`> Sale ($${amount}, #${id})`);
            POS.Integrado.sale(amount, id, true, (msg) => { this.Log('Sale: ' + JSON.stringify(msg)); }).then((res) => {
                this.Log('Sale ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnSaleSuccess' : 'OnSaleError', res);
                if(this.modalSale)
                {
                    this.Info(res.successful ? `Venta exitosa<br>${this.SaleData(res)}` : `Error en venta: ${res.responseMessage}`);
                    if(res.successful) this.modalSale = null;
                }
            }).catch(err => {
                this.Log('Sale error: ' + JSON.stringify(err));
                this.$emit('OnSaleError', {successful: false, responseMessage: err});
                if(this.modalSale) this.Info(`Error en venta`);
            });

        },
        LastSale() 
        {
            this.Log('> LastSale');
            POS.Integrado.lastSale().then((res) => {
                this.Log('LastSale ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnLastSaleSuccess' : 'OnLastSaleError', res);
                if(this.isOpen) this.Info(res.successful ? `Última venta<br>${this.SaleData(res)}` : `Error última venta: ${res.responseMessage}`);
            }).catch(err => {
                this.Log('LastSale error: ' + JSON.stringify(err))
                this.$emit('OnLastSaleError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Error última venta`);
            });
        },
        Refund(operationId) 
        {
            if(!operationId) operationId = prompt('Operation ID');
            if(!operationId) return console.log('Invalid operation id');
            this.Log(`> Refund (#${operationId})`);
            POS.Integrado.refund(operationId).then((res) => {
                this.Log('Refund ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnRefundSuccess' : 'OnRefundError', res);
                if(this.modalRefund)
                {
                    this.Info(res.successful ? `Anulación exitosa<br>${res.responseMessage}` : `Error en anulación de venta: ${res.responseMessage}`);
                    if(res.successful) this.modalRefund = null;
                }
            }).catch(err => {
                this.Log('Refund error: ' + JSON.stringify(err))
                this.$emit('OnRefundError', {successful: false, responseMessage: err});
                if(this.modalRefund) this.Info(`Error en anulación de venta`);
            });
        },
        Close()
        {
            this.Log('> Close');
            POS.Integrado.closeDay().then((res) => {
                this.Log('Close ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnCloseSuccess' : 'OnCloseError', res);
                if(this.isOpen) this.Info(res.successful ? `Cierre de caja OK<br>${res.responseMessage}` : `Error cierre de caja: ${res.responseMessage}`);
            }).catch(err => {
                this.Log('Close error: ' + JSON.stringify(err))
                this.$emit('OnCloseError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Error cierre de caja`);
            });
        },
        SalesDetail(printOnPos) 
        {
            printOnPos = !!printOnPos;
            this.Log(`> SalesDetail (${printOnPos ? 'print' : 'no-print'})`);
            POS.Integrado.salesDetail(printOnPos).then((res) => {
                if(Array.isArray(res))
                {
                    let lines = ['Detalles:'];
                    res.forEach(sale => {
                        lines.push(this.SaleData(sale));
                    });
                    lines = lines.join("\n");
                    if(!printOnPos) 
                    {
                        this.Log(lines);
                        this.showLog = true;
                    }
                }
                this.Log('SalesDetail ok: ' + JSON.stringify(res));
                this.$emit(res ? 'OnSalesDetailSuccess' : 'OnSalesDetailError', {successful: res});
            }).catch(err => {
                this.Log('SalesDetail error: ' + JSON.stringify(err))
                this.$emit('OnSalesDetailError', {successful: false, responseMessage: err});
            });
        },
        GetTotals()
        {
            this.Log('> GetTotals');
            POS.Integrado.getTotals().then((res) => {
                this.Log('GetTotals ok: ' + JSON.stringify(res));
                this.$emit(res.successful ? 'OnGetTotalsSuccess' : 'OnGetTotalsError', res);
                if(this.isOpen) this.Info(res.successful ? `Total de ventas OK<br>${res.responseMessage}` : `Error en total de ventas: ${res.responseMessage}`);
            }).catch(err => {
                this.Log('GetTotals error: ' + JSON.stringify(err))
                this.$emit('OnGetTotalsError', {successful: false, responseMessage: err});
                if(this.isOpen) this.Info(`Error en total de ventas`);
            });
        },
        Log(msg)
        {
            const now = new Date();

            // Extract individual parts of the date and time
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = now.getFullYear();

            // Combine into the desired format
            let date = `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;

            let lineMsg = `${date} - ${msg}`;

            let lines = this.log.split(/\n/g);
            lines.unshift(lineMsg);
            if(lines.length > 1000) lines.pop();
            this.log = lines.join('\n');
            localStorage.transbankLog = this.log;
            console.log(lineMsg);
        },
        SaleData(sale) {
            return `${sale.realTime.substr(0,2)}:${sale.realTime.substr(2,2)} ${sale.realDate.substr(0,2)}/${sale.realDate.substr(2,2)}\t${sale.operationNumber}\t${Util.Price(sale.amount)}\t****${sale.last4Digits} ${sale.cardType} (${sale.cardBrand})\tN. Cuota ${sale.feeNumber ? sale.feeNumber : sale.sharesNumber}\tC. Autorización ${sale.authorizationCode}`;
        },
        OpenRefund() {
            const match = this.log.match(/"operationNumber":"(\d+)"/);
            let id = '';
            if (match) id = match[1]; 
            this.modalRefund = {id: id};
        },
        Info(msg) {
            setTimeout(() => {
                this.Error(msg);
            }, 500);
        },
        Confirm(data) {
            this.modalConfirm = data;
        }
    },
    watch: {
        isConnected: function(n, o) {

        },
        autoConnect: function(n, o) {
            if(n) localStorage.setItem('autoConnect', 'true');
            else localStorage.removeItem('autoConnect');
        }
    },
    beforeDestroy() {
        this.Disconnect();
    }
}
</script>

<style>

</style>