import Vue from 'vue'
import App from './App.vue'
import ScrollTo from 'animated-scroll-to'
import VueSocketIO from 'vue-socket.io-extended'
import io from 'socket.io-client'

window.Util = require('./Util').default;
window.ScrollTo = ScrollTo;

const socket = io(process.env.VUE_APP_WS_URL, {
	autoConnect: false,
	//reconnectionAttempts: 5
});
window.Util.socket = socket;
Vue.use(VueSocketIO, socket);

Vue.config.productionTip = false;
Vue.component('main-view', require('./components/Main.vue').default);
Vue.component('modal', require('./components/common/Modal.vue').default);
Vue.component('franchise-view', require('./components/FranchiseView.vue').default);
Vue.component('virtual-menu-view', require('./components/VirtualMenu.vue').default);
Vue.component('totem-view', require('./components/TotemView.vue').default);

Vue.mixin({
	data() {
		return {
			Util: window.Util
		}
	},
	methods: {
		Error(err) {
			return this.$root.$children[0].ShowError(err);
		},
        OpenContact(phone)
        {
			if(!phone) return;
			var options = [];
			var phones = phone.split('-');
			phones.forEach(phone => {
				phone = phone.trim();
				options.push({text: `Llamar ${phone}`, function: () => {this.Util.Open(`tel:${phone}`)}});
				if(phone.indexOf('+569') > -1) options.push({text: `Abrir en Whatsapp (${phone})`, function: () => {this.Util.OpenWhatsapp(phone)}});
			});
			options.push({text: `Cancelar`, function: () => {}});
			this.Error(options);
        },
	},
	computed: {
		mobile() {
			return this.$root.$children[0].$data.platform == 'mobile';
		},
		tablet() {
			return this.$root.$children[0].$data.isTablet;
		},
		view() {
			return this.$root.$children[0].$data.type;
		}
	}
});

new Vue({
  render: h => h(App),
}).$mount('#app')
