export default {

    backend: process.env.VUE_APP_BACKEND_URL,
    ws: process.env.VUE_APP_WS_URL,
    gtag: null,

    ConnectionAddresses(server)
    {
        var backend = process.env.VUE_APP_BACKEND_URL;
        var ws = process.env.VUE_APP_WS_URL;
        if(server && server != 'DEFAULT')
        {
            //testing
            //8001 =>localhost:8001, localhost:7001, localhost:6001
            var port = parseInt(server);
            if(Number.isInteger(port))
            {
                backend = `http://localhost:${port}/`;
                ws = `http://localhost:${port-900}/`;
            }
            else
            {
                //production
                //a1 => a1.influye.app, a1a.influye.app, a1s.influye.app
                backend = `https://${server}.influye.app/`;
                ws = `https://${server}e.influye.online/`;
            }   
        } 
        return {
            'backend': backend,
            'ws': ws,
        };
    },

    Errors: {
        INVALID_TRANSACTION: 'Pedido inválido',
        INVALID_LOCAL: 'Este negocio no hace pedidos online',
        LOCAL_CLOSED: 'Este negocio esta cerrado actualmente',
        INVALID_TAKEAWAY_TIME: 'La hora en que quieres tu pedido no es válida',
        INVALID_ZONE: 'No hacemos entrega a la dirección ingresada',
        INVALID_PAYMENT_METHOD: 'Método de pago inválido',
        INVALID_ITEM: 'Algo anda mal con tu pedido...',
        ITEM_UNAVAILABLE: 'Uno de los items de tu pedido no se encuentra disponible',
        INVALID_QUANTITY: 'Algo anda mal con tu pedido...',
        INVALID_MODIFIER: 'Algo anda mal con tu pedido...',
        WRONG_TOTAL: 'Algo anda mal con tu pedido...',
        INVALID_TOTAL: 'Tu pedido no cumple el mínimo para repartos a domicilio',
        WRONG_ORDER: 'Algo anda mal con tu pedido...',
        INVALID_DISCOUNT: 'Cupón de descuento o código promocional inválido',
        INVALID_PHONE: 'El número de teléfono ingresado no es válido',
        TEMPORARY_DISABLED: 'Los pedidos están deshabilitados por el momento',
    },

    PyaCourierStatus: {
        REJECTED: 'Rechazado',
        EXPIRED: 'Expirado',
        PREORDER: 'Por confirmar',
        CONFIRMED: 'Confirmado',
        CANCELLED: 'Cancelado',
        IN_PROGRESS: 'En progreso',
        NEAR_PICKUP: 'Cerca de retiro',
        PICKED_UP: 'Recogido',
        NEAR_DROPOFF: 'Cerca de entrega',
        COMPLETED: 'Entregado'
    },

    OrderStatus: {
        CONFIRMATION: 'Confirmación necesaria',
        PENDING: 'Esperando que acepten tu pedido',
        WAITING: 'Esperando que aceptes la hora',
        WAITING_PAYMENT: 'Pago pendiente',
        WAITING_BANK_DEPOSIT: 'Pago pendiente',
        ACCEPTED: 'Pedido aceptado',
        PREPARING: 'En preparación',
        DELIVERY: 'En tránsito',
        TAKEAWAY_READY: 'Listo para retirar',
        COMPLETED: 'Pedido entregado',
        REJECTED: 'Pedido cancelado',
        SCHEDULED: 'Pedido agendado',
    },

    dictDaysEnEs: {
        'monday': 'Lunes',
        'tuesday': 'Martes',
        'wednesday': 'Miercoles',
        'thursday': 'Jueves',
        'friday': 'Viernes',
        'saturday': 'Sábado',
        'sunday': 'Domingo'
    },

    setFormatHour(config){
        this.format_hour = config;
    },

    img(str, path) {
        return this.backend + 'storage/' + path + '/' + str;
    },

    ig(ig) {
        if(!ig) return;
        var parts = ig.split('/');
        for(var i = parts.length-1; i >= 0; i--)
        {
            var txt = parts[i].trim();
            if(txt.length > 0) return txt;
        }
        return;
    },

    Copy(obj)
    {
        return JSON.parse(JSON.stringify(obj));
    },

    Date(date, format)
    {
        if(!date) return 'Desconocido';
        if(!format) format = 'L';

        var moment = require('moment');
        moment.locale('es');
        var ret = '';
        if(typeof date == 'string' && date.split('/').length == 3) ret = moment(date, 'DD/MM/YYYY').format(format);
        else ret = typeof(date) == 'number' ? moment.unix(date).format(format) : moment(date).format(format);
        moment.locale('en');
        return ret;
    },

    Number(number, short)
    {
        if(number === null || number === undefined) return '';
        //number = (Math.round(number*10)/10).toString().replace('.', ',');
        //number = number.toString().replace('.', ',');
        var suffix = '';
        if(short)
        {
            if(number >= 1000000)
            {
                number = number / 1000000;
                suffix = 'M';
            }
            else if(number >= 1000)
            {
                number = number / 1000;
                suffix = 'K';
            }
            //number = (Math.round(number*10)/10).toString().replace('.', ',');
            number = (Math.round(number*10)/10);
        }

        number = number.toString();
        var parts = number.split('.');
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (parts.length > 1 ? `,${parts[1]}` : '') + suffix;
        //return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + suffix;
    },

    Price(amount, short) {
        amount = parseInt(amount);
        var minus = amount < 0;
        return (minus ? '- ' : '') + '$' + this.Number(Math.abs(amount), short);
    },

    CancelledReason(reason)
    {
        var reasons = {
            'CANT_COMPLETE': 'Uno o más items de tu pedido no están disponibles',
            'NO_DELIVERY': 'El local está temporalmente sin delivery',
            'CLOSE_SOON': 'El local cerrará pronto y no alcanzará a hacer tu pedido',
            'BUSY': 'Hay muchos pedidos en el local y se demorará mucho en hacer el tuyo',
            'PENDING_TIMEOUT': 'El local no ha aceptado el pedido dentro del tiempo límite',
            'WAITING_TIMEOUT': 'El cliente no ha confirmado el pedido dentro del tiempo límite',
            'WAITING_PAYMENT_TIMEOUT': 'El cliente no ha realizado el pago dentro del tiempo límite',
            'DUPLICATED': 'Pedido duplicado',
        };
        return reasons[reason] ? reasons[reason] : reason;
    },

    TimeToMinutes(time)
    {
        if(time == 'now')
        {
            var date = new Date();
            return (date.getHours() * 60) + date.getMinutes();
        }

        if(!time || time.length !== 5) return null;
        var parts = time.split(':');
        if(parts.length !== 2) return null;
        var h = parseInt(parts[0]);
        var m = parseInt(parts[1]);
        if(h > 23 || h < 0 || m > 59 || m < 0) return null;
        return (h * 60) + m;
    },

    MinutesToTime(mins){
        if(mins === null || typeof mins == 'string' && mins.trim() === '') return '';
        mins = parseInt(mins);
        var ret = this.Zeropad((Math.floor(mins/60)%24),2).toString() + ':' + this.Zeropad(mins%60, 2).toString();
        return ret;
    },

    Zeropad(number, amount) {
        if (number >= Math.pow(10, amount)) {
            return number;
        }
        return (Array(amount).join(0) + number).slice(-amount);
    },

    TimeRange(time)
    {
        var moment = require('moment');
        time = moment(time, 'HH:mm');
        return this.convertTimeAmPm(time.subtract(15, 'minutes').format('HH:mm')) + ' y ' + this.convertTimeAmPm(time.add(30, 'minutes').format('HH:mm'));
    },

    Open(url)
    {
        window.open(url, '_blank');
    },

    OpenWhatsapp(phone)
    {
        phone = phone.replace('+', '').replace(/ /g, '');
        if(phone.length == 8) phone = '569'+phone;
        else if(phone.length == 9) phone = '56'+phone;
        this.Open(`https://${window.innerWidth >= 768 ? 'web' : 'api'}.whatsapp.com/send?phone=${phone}`);
    },

    ModifiersList(item, compactList)
    {
        var ret = [];
        var lastGroup = null;
        var lastMod = null;
        var modCount = 0;

        if(item.added_at) ret.push({type: 'ADDED_AT', value: Util.Date(item.added_at, 'HH:mm'), price: 0});

        if(item.modifiers && item.modifiers.length)
        {
            if(item.combo)
            {
                if(item.comment) ret.push({type: 'COMMENT', value: item.comment, price: 0});
                item.modifiers.forEach(subItem => {
                    ret.push({type: 'SUBITEM', value: subItem.title, price: subItem.price});
                
                    lastGroup = null;
                    subItem.modifiers.forEach(modifier => {
                        
                        var group = modifier.group ? modifier.group : modifier.section;
                        var key = `${group}-${modifier.title}`;
                        if(lastMod !== key) modCount = 1;
                        if(group)
                        {
                            if(!lastGroup || group != lastGroup)
                            {
                                ret.push({type: 'GROUP', value: group, price: 0});        
                                lastGroup = group;
                            }
                        }
    
                        ret.push({type: 'MODIFIER', value: (modCount>1?`(${modCount}) `:'')+modifier.title.replace(' > ', ' ⇄ '), price: modifier.price, key: key});
                        lastMod = key;
                        modCount++;
                    });
    
                    if(subItem.comment) ret.push({type: 'COMMENT', value: subItem.comment, price: 0});
                    lastMod = null;

                });
            }
            else
            {
                item.modifiers.forEach(modifier => {

                    
                    var group = modifier.group ? modifier.group : modifier.section;
                    var key = `${group}-${modifier.title}`;
                    if(lastMod !== key) modCount = 1;
                    if(group)
                    {
                        if(!lastGroup || group != lastGroup)
                        {
                            ret.push({type: 'GROUP', value: group, price: 0});        
                            lastGroup = group;
                        }
                    }
                    
                    ret.push({type: 'MODIFIER', value: (modCount>1?`(${modCount}) `:'')+modifier.title.replace(' > ', ' ⇄ '), price: modifier.price, key: key});
                    
                    lastMod = key;
                    modCount++;

                });

            }
            
        }

        if(item.comment && !item.combo) ret.push({type: 'COMMENT', value: item.comment, price: 0});


        var lastKey = null;
        for(var i = ret.length-1; i >= 0; i--)
        {
            if(lastKey && ret[i].key && ret[i].key == lastKey)
                ret.splice(i, 1);

            lastKey = ret[i].key;
        }

        if(compactList)
        {
            let newList = [];
            let tmp;

            let AddToCompactList = () => {
                if(!tmp) return;

                newList.push({type: 'MODIFIER', value: `${tmp.group.value}: ${tmp.modifiers.join(', ')}`});
                tmp = null;
            };

            ret.forEach(entry => {
                if(entry.type == 'GROUP')
                {
                    if(tmp) AddToCompactList();
                    tmp = {group: entry, modifiers: []};
                }
                else if(entry.type == 'MODIFIER')
                {
                    if(tmp) tmp.modifiers.push(entry.value);
                }
                else
                {
                    AddToCompactList();
                    newList.push(entry);
                }
            });
            AddToCompactList();
            ret = newList;
        }
        return ret;
    },

    ShortAddress(address)
    {
        var parts = address.split(',');
        return parts[0] + (parts.length > 1 ? ', ' + parts[1] : '');
    },

    ApplyOpacity(color, opacity)
    {
        if(color.indexOf('rgb') > -1)
        {
            color = color.split('(')[1];
            color = color.replace(')', '');
            var colors = color.split(/,/g);
            return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, ${opacity})`;
        }
        else if(color.indexOf('#') > -1)
        {
            var hex = color.replace('#', '');
            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }    
            
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            
            /* Backward compatibility for whole number based opacity values. */
            if (opacity > 1 && opacity <= 100) {
                opacity = opacity / 100;   
            }

            return `rgba(${r},${g},${b},${opacity})`;
        }
        return color;
    },

    convertTimeAmPm(time){
        if(this.format_hour == 'am_pm'){
            let brokenTime = time.split(':');
            let hour = parseInt(brokenTime[0]);

            let AmPm = 'AM';

            if (hour >= 12) {
                AmPm = 'PM';
                if (hour > 12) {
                    hour -= 12;
                }
            }

            return `${hour}:${brokenTime[1]} ${AmPm}`;
        }

        return time;
    },
    PayMethod(method) {
        if(method == 'CASH') return 'Efectivo';
        if(method == 'UNDEFINED') return 'Sin definir';
        if(method == 'ONLINE') return 'Webpay';
        if(method == 'BANK_DEPOSIT') return 'Transferencia';
        return method;
    },
    //borrar
    DateFormat(timestamp, format)
    {
        moment.locale('es');
        var ret = moment.unix(timestamp).format(format);
        moment.locale('en');
        return ret;
    },
    ApplyCustomization(customization)
    {
        var fontFamily = customization.font;
        var font = document.createElement('link');
        font.rel = 'stylesheet';
        font.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}:wght@400;500;700`;
        document.head.appendChild(font);

        var element = document.createElement('style');
        // Append style element to head
        document.head.appendChild(element);
        // Reference to the stylesheet
        var sheet = element.sheet;

        function MapCSS(haystack, replacement, defaultValue)
        {
            for(var key in replacement)
            {
                if(haystack.indexOf(key) > -1)
                    return replacement[key];
            }
            return defaultValue ? defaultValue : '';
        }

        let customizationCSS = `
        :root {
            --icon-border-radius: ${MapCSS(customization.logo_style, {ROUND: '10px', CIRCLE: '50%'})};
            --icon-shadow: ${MapCSS(customization.logo_style, {SHADOW: '0px 10px 10px 0px rgba(0,0,0,0.1)'})};
            --btn-shadow: ${MapCSS(customization.button_style, {SHADOW: '0px 3px 7px 0px rgba(0,0,0,0.2)'})};
            --btn-border-radius: ${MapCSS(customization.button_style, {ROUND: '3px', CIRCLE: '20px'})};
            --btn-border: ${MapCSS(customization.button_style, {BORDER: `3px`, THINBRD: '1px'}, '0px')};
            --left-bg: ${customization.left_bg};
            --left-fc: ${customization.left_fc};
            --left-bg-o: ${this.ApplyOpacity(customization.left_bg, 0.1)}; /*Color con opacidad*/
            --left-fc-o: ${this.ApplyOpacity(customization.left_fc, 0.1)}; /*Color con opacidad*/
            --center-bg: ${customization.center_bg};
            --center-fc: ${customization.center_fc};
            --center-c1: ${customization.center_c1};
            --center-c2: ${customization.center_c2};
            --center-bg-o: ${this.ApplyOpacity(customization.center_bg, 0.1)}; /*Color con opacidad*/
            --center-fc-o: ${this.ApplyOpacity(customization.center_fc, 0.1)}; /*Color con opacidad*/
            --center-c1-o: ${this.ApplyOpacity(customization.center_c1, 0.1)}; /*Color con opacidad*/
            --center-c2-o: ${this.ApplyOpacity(customization.center_c2, 0.1)}; /*Color con opacidad*/
            --right-bg: ${customization.right_bg};
            --right-fc: ${customization.right_fc};
            --right-c1: ${customization.right_c1};
            --right-c2: ${customization.right_c2};
            --right-bg-o: ${this.ApplyOpacity(customization.right_bg, 0.1)};  /*Color con opacidad*/
            --right-fc-o: ${this.ApplyOpacity(customization.right_fc, 0.1)};  /*Color con opacidad*/
            --right-c1-o: ${this.ApplyOpacity(customization.right_c1, 0.1)};  /*Color con opacidad*/
            --right-c2-o: ${this.ApplyOpacity(customization.right_c2, 0.1)};  /*Color con opacidad*/
            --modal-border-radius: ${MapCSS(customization.modal_style, {ROUND: '25px'})};
            --modal-bg: ${customization.modal_bg};
            --modal-fc: ${customization.modal_fc};
            --modal-c1: ${customization.modal_c1};
            --modal-c2: ${customization.modal_c2};
            --modal-bg-o: ${this.ApplyOpacity(customization.modal_bg, 0.1)};  /*Color con opacidad*/
            --modal-fc-o: ${this.ApplyOpacity(customization.modal_fc, 0.1)};  /*Color con opacidad*/
            --modal-c1-o: ${this.ApplyOpacity(customization.modal_c1, 0.1)};  /*Color con opacidad*/
            --modal-c2-o: ${this.ApplyOpacity(customization.modal_c2, 0.1)};  /*Color con opacidad*/
            --modal-cy: ${customization.modal_cy};
            --modal-cn: ${customization.modal_cn};
        }
        body, input {
            font-family: '${fontFamily}', serif;
        }
        `;

        if(customization.css)
        {
            customizationCSS += '\n\n' + customization.css;
        }

        this.SetCSS(customizationCSS);

    },

    FirstModifierPrice(item, withPriceOnly)
    {
        if(!item.modifiers) return null;
        for(var i = 0; i < item.modifiers.length; i++)
        {
            var group = item.modifiers[i];
            for(var j = 0; j < group.modifiers.length; j++)
            {
                var mod = group.modifiers[j];
                if(!withPriceOnly || mod.price > 0) return mod.price;
            }
        }
        return null;
    },
    ItemPrice(item, displayOption)
    {
        switch(displayOption)
        {
            case 'HIDDEN_ALL':
                return '';
            case 'ITEM_PRICE':
                return this.Price(item.price);
            case 'HIDDEN_ZERO': 
                return item.price > 0 ? this.Price(item.price) : '';
            case 'MODIFIER_PRICE':
                var modPrice = this.FirstModifierPrice(item);
                return this.Price(modPrice !== null ? modPrice : item.price);
            case 'AUTO':
                var modPrice = this.FirstModifierPrice(item, true);
                var price = item.price ? item.price : modPrice;
                return price !== null && price > 0 ? this.Price(price) : '';
        }
        return this.Price(item.price);
    },
    GroupRequirements(group)
    {
        var currentlySelected = 0;
        group.modifiers.forEach(modifier => {
            if(!group.multi_select && modifier.selected) currentlySelected++;
            else if(group.multi_select && modifier.count) currentlySelected += modifier.count;
        });

        /*
        if(this.ecommerceType == 'VIRTUAL_MENU')
        {
            if(group.min == 0 && group.max == 0) return {label: '', error: false};
            else if(group.min == 0 && group.max > 0) return {label: `(Hasta ${group.max})`, error: false};
            else if(group.min > 0 && group.max == group.min) return {label: `(${group.min} obligatorio)`, error: false};
            else if(group.min > 0 && group.max > 0) return {label: `(Entre ${group.min} y ${group.max})`, error: false};
            else if(group.max == 0 && group.min > 0) return {label: `(Mínimo ${group.min})`, error: false};
            return {label: '', error: false};    
        }
        */

        if(group.min == 0 && group.max == 0) return {label: '', error: false};
        else if(group.min == 0 && group.max > 0) return {label: `(Elige hasta ${group.max})`, error: currentlySelected > group.max};
        else if(group.min > 0 && group.max == group.min) return {label: `(Elige ${group.min})`, error: currentlySelected != group.min};
        else if(group.min > 0 && group.max > 0) return {label: `(Elige entre ${group.min} y ${group.max})`, error: currentlySelected < group.min || currentlySelected > group.max};
        else if(group.max == 0 && group.min > 0) return {label: `(Elige mínimo ${group.min})`, error: currentlySelected < group.min};
        return {label: '', error: false};
    },
    ClearLink() {
        const queryParams = window.location.search;

        return history.pushState(null, '', window.location.origin + queryParams);
    },
    Haversine(lat1, lon1, lat2, lon2, measurement = 'km'){
        const toRad = (value) => (value * Math.PI) / 180;
        const R = 6371; // Radio de la Tierra en km

        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const radLat1 = toRad(lat1);
        const radLat2 = toRad(lat2);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(radLat1) * Math.cos(radLat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = (R * c);

        if(measurement == 'mt') return parseFloat((distance * 1000).toFixed(2));

        return parseFloat(distance.toFixed(2));
    },
    UUIDv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    },
    PrintService(data)
    {
        var Axios = require('axios');
        Axios.post(process.env.VUE_APP_INFLUYE_SERVICE + 'print', {data: data, id: Math.random().toString()}).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    },
}