<template>
    <div class="fill flex column">

        <!-- Navbar -->
        <div class="navbar">
            <div class="head-franchise-container">
                <div class="head-franchise-content">
                    <div class="headbar-content">
                        <img v-if="local && local.logotype" :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin: 10px 0px" class="logo">
                    </div>

                    <!-- Input address -->
                    <div class="flex">
                        <div class="btn invert btn-hover relative unshrink flex align-bottom" @click="modalMap = {}"
                            style="margin-bottom: 10px; flex: 3 1 0%;" title="Ingresar dirección">
                            <div class="flex-fill">
                                <div class="s2 ta-left" v-if="address">{{Util.ShortAddress(address.title)}}</div>
                                <div class="s2 ta-left" v-else>Ingresar Dirección</div>
                            </div>
                            <i v-if="!address" class="icon icon-chevron-down c2" style="margin-left: 10px"></i>
                        </div>
                        <div v-if="nearbyPremises" class="btn btn-hover relative unshrink align-bottom" style="margin-left: 5px; max-height: 62px;">
                            <i class="icon icon-x c2" title="Borrar dirección"
                            @click="nearbyPremises = null; address = null"></i>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <!-- Banner -->
        <div class="banner-franchise">
            <div class="flex column relative top-banner" v-if="(mobile || tablet) && banner" style="height: 150px"
                :style="{ 'background-image': banner ? `url('${Util.img(banner, 'banners')}` : '', 'background-size': 'cover', 'background-position': 'center center' }">
            </div>
            <div class="flex column left-s bg relative" v-if="!mobile && !tablet && banner" style="height: 250px"
                :style="{ 'background-image': banner ? `url('${Util.img(banner, 'banners')}` : '', 'background-size': 'cover', 'background-position': 'center center' }">
            </div>

            <swiper v-if="carousel && carousel.length" class="swiper" :options="{
                loop: true,
                grabCursor: true,
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            }" style="cursor: grab">
                <swiper-slide v-for="entry in carousel" :key="entry.image">
                    <img :src="Util.img(entry.image, 'banners')" alt="" style="width: 100%">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <!--
        <div id="panelInstagram">
            <div class="ig-container">
                <a v-for="ig in instagramImages" :key="ig.image" target="_blank" :href="ig.url" title="Ir al Instagram">
                    <img :src="ig.image" width="200">
                </a>
            </div>
        </div>
        -->

        <!-- Contenido (locales) -->
        <div class="flex center-content"
            style="justify-content: space-between; margin-left: 0; padding-top: 20px; overflow: unset !important; height: auto; flex: 1 1 auto">
            <div v-if="!mobile" style="flex: 1; box-sizing: border-box;"></div>
            <div :style="{flex: 4, padding: mobile ? '0px 20px' : ''}">

                <div class="flex justify-center container">
                    <div class="btn card flex column" v-for="(local, i) in filteredPremises" :key="`local-${i}`" style="height: 100%">

                        <div class="s1 c1" :style="`color: ${local.color}; margin-bottom: 10px`">{{ local.name }}</div>

                        <div class="card-img" style="margin-bottom: 20px">
                            <img :src="Util.img(local.logotype, 'logos')" class="logo" style="width: 200px; height: 200px">
                        </div>
                        <div class="section-schedule">
                            <div class="card-schedule" v-for="(schedule, i) in franchiseSchedulesContinuous(local.settings.show_schedule)" :key="i">
                                <div class="s2 ta-left">{{ schedule.text }}</div>
                                <div v-for="(subSchedule, index) in schedule.schedules" :key="index">
                                    <div class="s1" :style="`color: ${local.color}`">{{ Util.convertTimeAmPm(subSchedule.start) }} a
                                        {{ Util.convertTimeAmPm(subSchedule.finish) }} {{format_hour == 'am_pm'? '' : 'Hrs.'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-icons" style="margin-bottom: 30px">
                            <div @click="OpenModalSchedule(local)" :title="`Ver horarios disponibles de ${local.name}`" class="c2 s3 ta-left txt-hover">Ver todos los horarios</div>
                        </div>
                        
                        <div class="flex-fill"></div>

                        <div class="card-icons" v-if="local">
                            <div v-if="local.latitude && local.longitude"
                                @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)"
                                target="_blank" :title="`Abrir ubicación de ${local.name} en Google Maps`"><i
                                    class="icon icon-map btn-hover"></i></div>
                            <div v-if="local.instagram" @click="Util.Open(local.instagram)" target="_blank"
                                title="Instagram"><i class="icon icon-instagram btn-hover"></i></div>
                            <div v-if="local.phone" @click="OpenContact(local)" target="_blank"
                                title="Contacto"><i class="icon icon-phone btn-hover"></i></div>
                            
                        </div>
                        <div class="btn invert btn-hover" :style="`background-color: ${local.color}; margin-top: 20px`"
                            @click="GoToSublocal(local)"><i class="icon icon-shopping-bag"
                                style="margin: 0px 10px 0px 0px"></i>Pedir Aquí</div>
                    </div>
                </div>
            </div>
            <div v-if="!mobile" style="flex: 1; box-sizing: border-box;"></div>
        </div>

        <div class="footer">
            <div class="footer-container">
                <div class="footer-content" v-if="local && (local.phone || ig)">

                    <div v-if="ig" class="btn-hover">
                        <i @click="Util.Open('https://instagram.com/'+ig)" class="icon icon-instagram icon-hover" title="Instagram"></i>
                    </div>
                    <div v-if="local.phone" class="btn-hover">
                        <i @click="OpenContact(local)" class="icon icon-phone icon-hover" title="Contacto"></i>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="s3">Powered by Influye</div>

                </div>
            </div>
        </div>

        <modal v-model="modalMap" title="Dirección">
            <div v-if="modalMap" class="fill relative flex column" style="overflow-x: hidden">

                <div v-if="!modalMap.map" class="fill flex column" style="padding: 20px">

                    <div class="btn input" style="margin-bottom: 10px">
                        <div class="label left c2 s3">Dirección (calle y número)</div>
                        <input v-model="modalMap.street" type="text" class="s2 w-label left"
                            placeholder="Ejemplo: Cervantes 480">
                    </div>

                    <div class="btn input" style="margin-bottom: 10px">
                        <div class="label left c2 s3">Comuna</div>
                        <input v-model="modalMap.neighborhood" type="text" class="s2 w-label left"
                            placeholder="Ejemplo: Concepción">
                    </div>

                    <div class="btn input" style="margin-bottom: 10px">
                        <div class="label left c2 s3">Número Dpto y/o referencias</div>
                        <input v-model="modalMap.comment" type="text" class="s2 w-label left"
                            placeholder="Ejemplo: Dpto 302 Torre A">
                    </div>

                    <div class="flex-fill"></div>

                    <div :class="`btn btn-hover flex column ${CheckTextAddress(modalMap) ? 'disabled' : 'invert'}`"
                        @click="!CheckTextAddress(modalMap) ? ContinueTextAddres() : null">
                        <div>Continuar</div>
                        <div class="s3 c2" v-if="CheckTextAddress(modalMap)">{{ CheckTextAddress(modalMap).reason }}</div>
                    </div>

                </div>

                <div v-if="modalMap.map" class="fill flex column">
                    <map-component @OnMarkerChanged="MapLocationChanged($event, false)" ref="map" v-model="modalMap"
                        style="z-index: 0"></map-component>

                    <div @click="$set(modalMap, 'map', false)" class="absolute btn btn-hover"
                        style="left: 20px; top: 20px; z-index: 100" title="Atrás"><i class="icon icon-chevron-right"
                            style="transform: rotate(180deg)"></i></div>

                    <div class="absolute flex justify-center"
                        style="pointer-events: none; right: 10px; left: 10px; bottom: 130px; ">
                        <div
                            style="font-weight: bold; background-color: white; color: black; padding: 10px 20px; border-radius: 2px; text-align: center; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3)">
                            Confirma tu dirección exacta en el mapa o arrastra el pin</div>
                    </div>

                    <div class="absolute" v-if="modalMap.location" style="right: 20px; left: 20px; bottom: 20px">
                        <div @click="ConfirmAddress()"
                            :class="`btn btn-hover flex column ${!modalMap.location.in_range || modalMap.loading ? 'disabled' : 'invert'}`">
                            <div>Confirmar Ubicación</div>
                            <div class="s3 c2" v-if="!modalMap.location.in_range">No llegamos a esta dirección</div>
                            <div class="s3 c2" v-if="modalMap.location.in_range">
                                {{ Util.ShortAddress(modalMap.location.title) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal v-model="modalSchedule" :title="`${modalSchedule && modalSchedule.local ? modalSchedule.local : 'Horarios'}`"
            desktopWidth="500px">
            <div class="flex column" style="padding: 20px;" v-if="modalSchedule">
                <div class="flex">
                    <div class="flex-fill">
                        <div v-if="!openDaysContinuous.same" class="s1" style="margin-bottom: 20px">Delivery <i
                                class="icon icon-motorbike"></i></div>
                        <div v-for="(d, day) in days" :key="d">
                            <div :class="`${day == today ? 'cy' : 'c1'} s1`">{{ d }}</div>

                            <div v-if="!openDaysContinuous.DELIVERY[day].length" class="c2 s2">
                                {{ !openDaysContinuous.same ? 'No disponible' : 'Cerrado' }}</div>
                            <div v-else>
                                <div v-for="(schedule, index) in openDaysContinuous.DELIVERY[day]" :key="d + index"
                                    class="c1 s2">{{ Util.convertTimeAmPm(PatchTime(schedule.start)) }} a {{ Util.convertTimeAmPm(PatchTime(schedule.finish)) }} {{format_hour == 'am_pm'? '' : 'hrs'}}
                                </div>
                            </div>

                            <div v-if="day !== 'sunday'" class="separator"></div>
                        </div>
                    </div>
                    <div class="flex-fill" v-if="!openDaysContinuous.same" style="margin-left: 10px;">
                        <div class="s1" style="margin-bottom: 20px">Retirar <i class="icon icon-paper-bag"></i></div>
                        <div v-for="(d, day) in days" :key="d">
                            <div :class="`${day == today ? 'cy' : 'c1'} s1`">{{ d }}</div>

                            <div v-if="!openDaysContinuous.TAKEAWAY[day].length" class="c2 s2">No disponible</div>
                            <div v-else>
                                <div v-for="(schedule, index) in openDaysContinuous.TAKEAWAY[day]" :key="d + index"
                                    class="c1 s2">{{ Util.convertTimeAmPm(PatchTime(schedule.start)) }} a {{ Util.convertTimeAmPm(PatchTime(schedule.finish)) }} {{format_hour == 'am_pm'? '' : 'hrs'}}
                                </div>
                            </div>

                            <div v-if="day !== 'sunday'" class="separator"></div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal v-model="modalError" :sizeAuto="true">
            <div style="padding: 20px" v-if="modalError">
                <div v-if="typeof modalError == 'string'" v-html="modalError"></div>
                <div v-if="Array.isArray(modalError)" class="flex column" style="margin-bottom: -10px">
                    <div @click="option.function(); modalError = null;" class="btn btn-hover" v-for="option in modalError" :key="option.text" style="margin-bottom: 10px">{{option.text}}</div>
                </div>
            </div>
        </modal>

        <transition leave-active-class="animated fadeOut faster">
        <div v-if="loading" class="flex column justify-center align-center" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: #f7f8fc; z-index: 10">
            
            <div v-if="loading === 'ERROR'">
                <div style="color: #CCC; font-size: 18px; font-weight: 500; margin: 20px; text-align: center">Oops! Ha ocurrido un error o el negocio no está disponible actualmente</div>
            </div>
            <div v-else>
                <div style="position: relative; width: 100px; height: 100px">
                    <img :src="require('../assets/circle-notch.png')" alt="" width="100" class="spin absolute" style="left: 0; top: 0; right: 0; bottom: 0">
                    <img :src="require('../assets/influye-logo.png')" alt="" width="100" class="absolute" style="left: 0; top: 0; right: 0; bottom: 0">
                </div>
                
                <a style="color: #CCC; font-size: 12px; font-weight: 500; margin-top: 10px" href="https://influyeapp.cl/" target="_blank">Powered by Influye</a>
            </div>
        </div>
        </transition>

    </div>
</template>

<script>
import MapComponent from './common/MapComponent.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.min.css";
var moment = require('moment');
export default {
    components: { MapComponent, Swiper, SwiperSlide },
    data() {
        return {
            loading: true,
            local: null,
            locals: null,
            ecommerceType: null,
            nearbyPremises: null,
            address: null,
            modalSchedule: null,
            modalMap: null,
            timestamp: 0,
            today: null,
            shipmentType: null,
            days: {
                'monday': 'Lunes',
                'tuesday': 'Martes',
                'wednesday': 'Miercoles',
                'thursday': 'Jueves',
                'friday': 'Viernes',
                'saturday': 'Sábado',
                'sunday': 'Domingo'
            },
            timePatch: null,
            banner: null,
            carousel: null,
            instagramImages: [],
            format_hour: null,
            format_range: null,
            modalError: null,
        }
    },
    mounted() {
        if(typeof data == 'undefined' )
        {
            axios.get('data').then(({ data }) => {
                this.InitialSetup(data);
            }).catch(err => {
                this.loading = 'ERROR';
            });
        }
        else
        {
            this.InitialSetup(data.data);
        }
    },
    methods: {
        InitialSetup(data)
        {
            var momentES = moment();
            momentES.locale('es');
    
            this.local = data.franchise;
            this.locals = data.locals;
            this.ecommerceType = data.ecommerce_type;
            this.format_hour = data.format_hour;
            this.Util.setFormatHour(this.format_hour);
            this.format_range = data.format_range;
            this.banner = data.banner;
            this.carousel = data.carousel;
            this.timestamp = data.timestamp;
            this.today = moment.unix(this.timestamp).format('dddd').toLowerCase();
            this.shipmentType = data.shipment_type;
            this.timePatch = data.time_patch;
            this.instagramImages = data.ig_images;

            /*
            const idElement = document.querySelector('#app');
            if (idElement) {
                idElement.style.height = 'auto';
                idElement.style.setProperty('height', 'auto', 'important');
            }
            */

            this.loading = false;
            return this.ApplyCustomization(data.customization);
        },
        ContinueTextAddres() {
            this.$set(this.modalMap, 'loading', true);
            let params = `${this.modalMap.street}, ${this.modalMap.neighborhood}`;
            params = params.replace(/\//g, ' ');

            let data = {
                search: params,
                locals: this.locals
            };

            axios.post(`/addresses/franchise`, data).then(({ data }) => {
                if (this.modalMap) {
                    if (data.in_range) this.$set(this.modalMap, 'nearbyPremises', data.locals.filter(local => !local.error && local.enabled));

                    this.$set(this.modalMap, 'latitude', data.in_range ? data.locals[0].latitude : data.lat);
                    this.$set(this.modalMap, 'longitude', data.in_range ? data.locals[0].longitude : data.lon);
                    this.$set(this.modalMap, 'map', true);

                    this.$nextTick(() => {
                        this.$set(this.modalMap, 'loading', false);
                        this.$set(this.modalMap, 'location', data);
                        return this.MapLocationChanged(data, true);
                    });
                }

            }).catch(err => {
                this.$set(this.modalMap, 'loading', false);
            });
        },
        CheckTextAddress(address)
        {
            if(address.loading) return {reason: ''};
            if(!address.street || address.street.length < 3) return {reason: 'Ingresa una dirección válida'};
			if(!address.neighborhood || address.neighborhood.length < 3) return {reason: 'Ingresa una comuna válida'};

            return false;
        },
        MapLocationChanged(location, skipGeocoding) {
            var lat = location.lat ? location.lat : (location.latitude ? location.latitude : null);
            var lon = location.lon ? location.lon : (location.lng ? location.lng : (location.longitude ? location.longitude : null));

            this.$refs.map.FlyTo({ latitude: lat, longitude: lon });
            if (skipGeocoding) return;

            var time = '';
            if (this.selectedTime == 'SCHEDULE' && this.scheduleDay !== 'NONE' && this.scheduleTime !== 'NONE')
                time = `?time=${this.scheduleTime} ${this.scheduleDay}`;

            this.$set(this.modalMap, 'loading', true);

            let data = {
                latitude: lat,
                longitude: lon,
                isFranchise: true,
                locals: this.locals
            };

            if (this.customerName) data.name = this.customerName;
            if (this.customerPhone) data.phone = this.customerPhone;
            if (this.customerEmail) data.email = this.customerEmail;

            data.name = 'user franchise';
            data.phone = '+56935926523';
            data.email = 'user_franchise@gmail.com';

            axios.post(`/address/coordinates${time}`, data).then(({ data }) => {
                if (this.modalMap) {
                    if (data.in_range) this.$set(this.modalMap, 'nearbyPremises', data.locals.filter(local => !local.error && local.enabled));

                    this.$set(this.modalMap, 'loading', false);
                    this.$set(this.modalMap, 'location', data);
                }
                if (data.error) this.Error(data.message);
            }).catch(err => {
                if (this.modalMap) {
                    this.$set(this.modalMap, 'loading', false);
                    this.$set(this.modalMap, 'location', null);
                }
            });
        },
        ConfirmAddress() {
            if (this.modalMap && this.modalMap.nearbyPremises) {
                this.nearbyPremises = this.modalMap.nearbyPremises;
                let firstLocal = this.filteredPremises[0];

                let title = null;
                if (this.modalMap?.location?.title) title = this.modalMap.location.title;

                this.address = {
                    street: this.modalMap.street,
                    number: this.modalMap.number,
                    neighborhood: this.modalMap.neighborhood,
                    comment: this.modalMap.comment,
                    address: `${this.modalMap.street}, ${this.modalMap.neighborhood} ${this.modalMap.comment ? '(' + this.modalMap.comment + ')' : ''}`,
                    lat: this.modalMap.location.lat,
                    lon: this.modalMap.location.lon,
                    enabled: null,
                    cost: null,
                    title: title,
                    timestamp: null,
                    discount: null
                };

                if (this.nearbyPremises.length == 1) {
                    let address = this.Util.Copy(this.address);
                    address.enabled = !!this.nearbyPremises[0].enabled;
                    address.cost = this.nearbyPremises[0].enabled.cost;
                    address.timestamp = this.nearbyPremises[0].enabled.timestamp;
                    address.discount = this.nearbyPremises[0].enabled.discount;

                    const queryAddress = `?address=${encodeURIComponent(JSON.stringify(address))}`;
                    this.Util.Open(`https://${firstLocal.settings.ecommerce_url}${queryAddress}`);
                }

                return this.modalMap = null;
            }
        },
        OpenModalSchedule(local) {
            var ret = {
                general: { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false },
                DELIVERY: { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [], openNow: false },
                TAKEAWAY: { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [], openNow: false },
                same: true,
                local: local.name
            };

            if (!local.settings.open_at) return ret;

            if (local.settings.ecommerce_type == 'RESTAURANT') {

                var today = moment.unix(this.timestamp);
                var todayMins = (today.get('minutes')) + (today.get('hours') * 60);

                var isSameSchedule = true;
                local.settings.open_at.forEach(schedule => {
                    ret.general[schedule.day] = true;
                    if (schedule.delivery) ret.DELIVERY[schedule.day].push(schedule);
                    if (schedule.takeaway) ret.TAKEAWAY[schedule.day].push(schedule);
                    if (schedule.delivery != schedule.takeaway) isSameSchedule = false;

                    if (schedule.day == this.today) {
                        var startMins = this.Util.TimeToMinutes(schedule.start);
                        var finishMins = this.Util.TimeToMinutes(schedule.finish);
                        if (todayMins >= startMins && todayMins <= finishMins) {
                            if (schedule.delivery) ret.DELIVERY.openNow = true;
                            if (schedule.takeaway) ret.TAKEAWAY.openNow = true;
                        }
                    }

                });
                ret.same = isSameSchedule;
            }
            else if (local.settings.ecommerce_type == 'SHIPMENT' && this.shipmentType !== 'AUTO') {
                let today = moment.unix(this.timestamp);
                let todayMins = (today.get('minutes')) + (today.get('hours') * 60);
                let isSameSchedule = true;
                local.settings.open_at.forEach(schedule => {
                    ret.general[schedule.day] = true;
                    if(schedule.delivery) ret.DELIVERY[schedule.day].push(schedule);
                    if(schedule.takeaway) ret.TAKEAWAY[schedule.day].push(schedule);
                    if(schedule.delivery != schedule.takeaway) isSameSchedule = false;

                    if(schedule.day == this.today){
                        let startMins = this.Util.TimeToMinutes(schedule.start);
                        let finishMins = this.Util.TimeToMinutes(schedule.finish);
                        if(todayMins >= startMins && todayMins <= finishMins){
                            if(schedule.delivery) ret.DELIVERY.openNow = true;
                            if(schedule.takeaway) ret.TAKEAWAY.openNow = true;
                        }
                    }
                });
                ret.same = isSameSchedule;
            }

            this.modalSchedule = ret;
        },
        PatchTime(time) {
            if (!this.timePatch) return time;

            var minutes = this.Util.TimeToMinutes(time) + (this.timePatch * 60);

            return this.Util.MinutesToTime(minutes);
        },
        GoToSublocal(local)
        {
            let premise = this.nearbyPremises ? this.nearbyPremises.filter(premise => premise.id == local.id) : null;

            let queryAddress = '';
            if(premise && premise.length) 
            {
                premise = premise[0];
                let address = this.Util.Copy(this.address);
                address.enabled = !!premise.enabled;
                address.cost = premise.enabled.cost;
                address.timestamp = premise.enabled.timestamp;
                address.discount = premise.enabled.discount;
                queryAddress = `?address=${encodeURIComponent(JSON.stringify(address))}`;
            }

            this.Util.Open(`http://${local.settings.ecommerce_url}${queryAddress}`)
        },
        ApplyCustomization(customization) {
            let fontFamily = customization.font;
            let font = document.createElement('link');
            font.rel = 'stylesheet';
            font.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}:wght@400;500;700`;
            document.head.appendChild(font);

            let element = document.createElement('style');
            document.head.appendChild(element);
            let sheet = element.sheet;

            function MapCSS(haystack, replacement) {
                for (var key in replacement) {
                    if (haystack.indexOf(key) > -1)
                        return replacement[key];
                }
                return '';
            }

            //LOGO STYLE
            sheet.insertRule(`.logo {
                border-radius: ${MapCSS(customization.logo_style, { ROUND: '10px', CIRCLE: '50%' })};
                box-shadow: ${MapCSS(customization.logo_style, { SHADOW: '0px 10px 10px 0px rgba(0,0,0,0.1)' })};
            }`, 0);

            //BUTTONS & INPUT
            sheet.insertRule(`.btn {
                box-shadow: ${MapCSS(customization.button_style, { SHADOW: '0px 3px 7px 0px rgba(0,0,0,0.2)' })};
                border-radius: ${MapCSS(customization.button_style, { ROUND: '3px', CIRCLE: '20px' })};
                border: ${MapCSS(customization.button_style, { BORDER: `1px solid ${customization.cp}` })};
            }`, 1);

            //MODAL
            sheet.insertRule(`.modal {
                border-radius: ${MapCSS(customization.modal_style, { ROUND: '25px' })};
            }`, 2);

            //LEFT
            sheet.insertRule(`.left-menu, .left-s {
                background-color: ${customization.left_bg};
            }`, 3);
            sheet.insertRule(`.left-menu, .left-s {
                color: ${customization.left_fc};
            }`, 4);
            sheet.insertRule(`.left-menu .separator {
                border-color: ${customization.left_fc};
            }`, 5);
            sheet.insertRule(`.left-menu .btn{
                color: ${customization.left_bg};
                background-color: ${customization.left_fc};
            }`, 6);


            //CENTER
            sheet.insertRule(`.center-content {
                background-color: ${customization.center_bg};
                color: ${customization.center_fc};
            }`, 7);
            sheet.insertRule(`.center-content .btn {
                background-color: ${customization.center_c2};
            }`, 8);
            sheet.insertRule(`.center-content .btn {
                color: ${customization.center_c1};
            }`, 9);
            sheet.insertRule(`.center-content .btn.invert {
                color: ${customization.center_c2};
                background-color: ${customization.center_c1};
            }`, 10);
            sheet.insertRule(`.center-content .btn.invert.secondary {
                background-color: ${this.Util.ApplyOpacity(customization.center_c1, 0.1)};
                color: ${customization.center_c1};
            }`, 11);

            //RIGHT
            sheet.insertRule(`.right-menu, .right-menu .bg {
                background-color: ${customization.right_bg};
                color: ${customization.right_fc};
            }`, 12);

            sheet.insertRule(`.right-menu .separator {
                border-color: ${customization.right_c1};
            }`, 13);

            sheet.insertRule(`.right-menu .btn {
                background-color: ${customization.right_c2};
                color: ${customization.right_c1};
            }`, 14);

            sheet.insertRule(`.right-menu .btn.invert {
                background-color: ${customization.right_c1};
                color: ${customization.right_c2};
            }`, 15);

            sheet.insertRule(`.right-menu .btn.invert.secondary {
                background-color: ${this.Util.ApplyOpacity(customization.right_c1, 0.1)};
                color: ${customization.right_c1};
            }`, 16);

            //MODAL
            sheet.insertRule(`.modal {
                background-color: ${customization.modal_bg};
                color: ${customization.modal_fc};
            }`, 17);
            sheet.insertRule(`.modal .separator, .bank-data, .bank-data td {
                border-color: ${customization.modal_c1};
            }`, 18);
            sheet.insertRule(`.modal-container .btn {
                color: ${customization.modal_c1};
                background-color: ${customization.modal_c2};
            }`, 19);

            sheet.insertRule(`.modal-container .btn.invert, .modal .counter {
                color: ${customization.modal_c2};
                background-color: ${customization.modal_c1};
            }`, 20);

            sheet.insertRule(`.modal-container .btn.invert.secondary {
                background-color: ${this.Util.ApplyOpacity(customization.modal_c1, 0.1)};
                color: ${customization.modal_c1};
            }`, 21);

            sheet.insertRule(`.modal .cy {
                color: ${customization.modal_cy};
            }`, 22);

            sheet.insertRule(`.modal .cn {
                color: ${customization.modal_cn};
            }`, 23);

            sheet.insertRule(`.modal .cp {
                color: ${customization.modal_c1};
            }`, 24);

            sheet.insertRule(`.modal .cs {
                color: ${customization.modal_c2};
            }`, 25);

            sheet.insertRule(`body, input {
                font-family: '${fontFamily}', serif;
            }`, 26);

            sheet.insertRule(`@media (min-width: 767px) {
                ::-webkit-scrollbar {
                    width: 14px;
                }
            }`, 27);

            sheet.insertRule(`@media (min-width: 767px) {
                ::-webkit-scrollbar-track {
                    background: transparent;
                }
            }`, 28);

            sheet.insertRule(`@media (min-width: 767px) {
                ::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    border: 4px solid transparent;
                    background-clip: content-box;
                }
            }`, 29);

            sheet.insertRule(`@media (min-width: 767px) {
                ::-webkit-scrollbar-thumb {
                    background-color: ${customization.left_fc};
                }
            }`, 30);

            sheet.insertRule(`@media (min-width: 767px) {
                .center-content::-webkit-scrollbar-thumb, .center-content *::-webkit-scrollbar-thumb {
                    background-color: ${customization.center_fc};
                }
            }`, 31);

            sheet.insertRule(`@media (min-width: 767px) {
                .right-menu::-webkit-scrollbar-thumb, .right-menu *::-webkit-scrollbar-thumb {
                    background-color: ${customization.right_fc};
                }
            }`, 32);

            sheet.insertRule(`@media (min-width: 767px) {
                .modal::-webkit-scrollbar-thumb, .modal *::-webkit-scrollbar-thumb {
                    background-color: ${customization.modal_fc};
                }
            }`, 33);

            sheet.insertRule(`.navbar {
                background-color: ${customization.navbar_bg};
            }`, 34);

            sheet.insertRule(`.footer {
                background-color: ${customization.footer_bg};
                color: ${customization.footer_fc};
            }`, 35);

            sheet.insertRule(`.dividing-line {
                border-top: 3px solid ${customization.footer_c2};
            }`, 36);

            sheet.insertRule(`.navbar .btn {
                color: ${customization.footer_fc};
            }`, 37);
        },
        MergeSequentialDays(scheduleType, openDays){
            const schedule = openDays[scheduleType];
            const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

            for (let i = 0; i < daysOfWeek.length; i++) {
                const currentDay = daysOfWeek[i];
                const nextDay = daysOfWeek[(i + 1) % daysOfWeek.length];

                if ((schedule[currentDay] && schedule[currentDay].length) && (schedule[nextDay] && schedule[nextDay].length)) {
                    const indexFinish = schedule[currentDay].findIndex(item => item.finish == '23:59');
                    const indexStart = schedule[nextDay].findIndex(item => item.start == '00:00');

                    if (indexFinish != -1 && indexStart != -1) {
                        const nextDayFinish = schedule[nextDay][indexStart].finish.split(':');

                        if (schedule[currentDay][indexFinish].finish == '23:59' && schedule[nextDay][indexStart].start == '00:00') {
                            if(parseInt(nextDayFinish[0]) <= 8){
                                schedule[currentDay][indexFinish].finish = schedule[nextDay][indexStart].finish;
                                schedule[nextDay].splice(indexStart, 1);
                            }else{
                                schedule[currentDay][indexFinish].finish = '08:00';
                                schedule[nextDay][indexStart].start = '08:00';
                            }
                        }
                    }
                }
            }

            openDays[scheduleType] = schedule;

            return openDays;
        },
        OpenContact(local)
        {
            if(local.phone)
            {
                var options = [];
                var phones = local.phone.split('-');
                phones.forEach(phone => {
                    phone = phone.trim();
                    options.push({text: `Llamar ${phone}`, function: () => {this.Util.Open(`tel:${phone}`)}});
                    if(phone.indexOf('+569') > -1) options.push({text: `Abrir en Whatsapp (${phone})`, function: () => {this.Util.OpenWhatsapp(phone)}});
                });
                options.push({text: `Cancelar`, function: () => {}});
                this.modalError = options;
            }
        },
        franchiseSchedulesContinuous(openDays){
            if(this.format_range != 'continuous') return openDays;

            // Recorrer los días de la semana
            for (let i = 0; i < openDays.length; i++) {
                const currentDay = openDays[i];
                const nextDay = openDays[(i + 1) % openDays.length];  // El próximo día (ciclo semanal)

                const currentSchedules = currentDay.schedules;
                const nextSchedules = nextDay.schedules;

                // Verificar si el día actual termina a las 23:59 y el siguiente comienza a las 00:00
                const indexFinish = currentSchedules.findIndex(item => item.finish === '23:59');
                const indexStart = nextSchedules.findIndex(item => item.start === '00:00');

                if (indexFinish !== -1 && indexStart !== -1) {
                    // Obtener las horas del horario del siguiente día
                    const nextDayFinish = nextSchedules[indexStart].finish.split(':');

                    // Unir los horarios si el siguiente día termina antes de las 08:00
                    if (parseInt(nextDayFinish[0]) <= 8) {
                        currentSchedules[indexFinish].finish = nextSchedules[indexStart].finish;  // Fusionar el horario
                        nextSchedules.splice(indexStart, 1);  // Eliminar el horario ya fusionado del siguiente día
                    } else {
                        // Si el siguiente día termina después de las 08:00, ajustar los horarios
                        currentSchedules[indexFinish].finish = '08:00';
                        nextSchedules[indexStart].start = '08:00';
                    }
                }
            }

            return openDays;
        }
    },
    computed: {
        filteredPremises() {
            if (!this.locals) return [];
            if (!this.nearbyPremises) return this.locals;

            const nearbyPremisesIds = Util.Copy(this.nearbyPremises).map(premise => premise.id);

            const filteredLocals = Util.Copy(this.locals).filter(local => nearbyPremisesIds.includes(local.id));

            return filteredLocals;
        },
        ig() {
            if (!this.local || !this.local.instagram) return;
            var parts = this.local.instagram.split('/');
            var ret = null
            for(var i = parts.length-1; i >= 0; i--)
            {
                var txt = parts[i].trim();
                if(txt.length > 0) return txt;
            }
            return;
        },
        openDaysContinuous(){
            if(!this.modalSchedule) return;
            if(this.format_range != 'continuous') return this.modalSchedule;
            let schedules = this.Util.Copy(this.modalSchedule);
            schedules = this.MergeSequentialDays('DELIVERY', schedules);
            schedules = this.MergeSequentialDays('TAKEAWAY', schedules);

            return schedules;
        }
    }
}
</script>

<style lang="scss">
a {
    text-decoration: none;
}

button {
    font-family: inherit;
    font-size: inherit;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
            transform: none !important;
        }
    }

}

.unshrink {
    flex-shrink: 0;
}

.center-content {
    width: 100%;
    height: 100%;
    margin-right: 330px;
    margin-left: 250px;
}

.today {
    color: #4c77ff !important;
    font-weight: 700 !important;
}

.closed.today {
    color: #ff4c79 !important;
}

.top-banner i {
    margin: 0px 12px;
}

.banner-franchise {
    grid-area: 2 / 1 / 3 / 4;
}

.navbar {
    display: block;
    background-image: none;
}

.head-franchise-container {
    display: block;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
}

.head-franchise-content {
    display: flex;
    justify-content: space-between;
    /* Alinea los elementos a los lados */
    align-items: center;
    /* Centra verticalmente los elementos */
    overflow-wrap: anywhere;
}

.headbar-content {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 0;
}

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 30px;
    align-items: start;
    padding-bottom: 20px;
}

.card {
    padding: 30px;
    height: auto;
    width: 100%;
    align-content: space-evenly;
    flex-wrap: wrap;
}

.card-img {
    height: 200px;
    width: 100%;
}

.card-img img {
    height: 100%;
    width: 100%;
}

.card-icons {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.card-icons i {
    font-size: 20px;
}

.card-schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    justify-content: center;
    margin-bottom: 15px;
}

.footer {
    padding: 30px 0;

    .footer-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 15px;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 150px;
    }
}

.dividing-line {
    width: 30px;
    height: 10px;
    margin-top: 5px;
}

.footer-links ul,
.footer-social ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li,
.footer-social ul li {
    margin-bottom: 10px;
}

.footer-links a,
.footer-social a {
    text-decoration: none;
    color: #fff;
}

.footer-bottom {
    text-align: center;
    font-size: 14px;
}

.footer-social i {
    font-size: 24px;
    margin-right: 10px;
}


@media (max-width: 767px) {
    .center-content {
        margin-left: 0;
        margin-right: 0;
    }

    .container {
        grid-template-columns: 1fr;
    }
    /*
    .footer-content {
        flex-direction: column;
    }
    */
}
</style>